<script>
export default {
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
}
</script>

<template>
  <!-- start page title -->
  <div class="row">
    <div class="col-lg-6">
      <div class="page-title-box py-2">
        <h1 class="text-lg-left text-center mb-0">{{ title }}</h1>
      </div>
    </div>
    <div class="col-lg-6 order-lg-last order-first">
      <div class="page-title-right">
        <b-breadcrumb :items="items" class="px-0 m-0 d-flex justify-content-lg-end justify-content-center"></b-breadcrumb>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
